/*
  	Keenthemes Icons Font: Ki
  	Creation date: 03/08/2020
 */

@font-face {
    font-family: "Ki";
    src: url("./Ki.eot");
    src: url("./Ki.eot?#iefix") format("embedded-opentype"), url("./Ki.woff") format("woff"), url("./Ki.ttf") format("truetype"), url("./Ki.svg#Ki") format("svg");
    font-weight: normal;
    font-style: normal;
}

.ki {
    font-size: 1rem;
}

.ki:before {
    font-family: "Ki";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

.ki-double-arrow-next:before {
    content: "\f100";
}

.ki-double-arrow-back:before {
    content: "\f101";
}

.ki-double-arrow-down:before {
    content: "\f102";
}

.ki-double-arrow-up:before {
    content: "\f103";
}

.ki-long-arrow-back:before {
    content: "\f104";
}

.ki-arrow-next:before {
    content: "\f105";
}

.ki-arrow-back:before {
    content: "\f106";
}

.ki-long-arrow-next:before {
    content: "\f107";
}

.ki-check:before {
    content: "\f108";
}

.ki-arrow-down:before {
    content: "\f109";
}

.ki-minus:before {
    content: "\f10a";
}

.ki-long-arrow-down:before {
    content: "\f10b";
}

.ki-long-arrow-up:before {
    content: "\f10c";
}

.ki-plus:before {
    content: "\f10d";
}

.ki-arrow-up:before {
    content: "\f10e";
}

.ki-round:before {
    content: "\f10f";
}

.ki-reload:before {
    content: "\f110";
}

.ki-refresh:before {
    content: "\f111";
}

.ki-solid-plus:before {
    content: "\f112";
}

.ki-bold-close:before {
    content: "\f113";
}

.ki-solid-minus:before {
    content: "\f114";
}

.ki-hide:before {
    content: "\f115";
}

.ki-code:before {
    content: "\f116";
}

.ki-copy:before {
    content: "\f117";
}

.ki-up-and-down:before {
    content: "\f118";
}

.ki-left-and-right:before {
    content: "\f119";
}

.ki-bold-triangle-bottom:before {
    content: "\f11a";
}

.ki-bold-triangle-right:before {
    content: "\f11b";
}

.ki-bold-triangle-top:before {
    content: "\f11c";
}

.ki-bold-triangle-left:before {
    content: "\f11d";
}

.ki-bold-double-arrow-up:before {
    content: "\f11e";
}

.ki-bold-double-arrow-next:before {
    content: "\f11f";
}

.ki-bold-double-arrow-back:before {
    content: "\f120";
}

.ki-bold-double-arrow-down:before {
    content: "\f121";
}

.ki-bold-arrow-down:before {
    content: "\f122";
}

.ki-bold-arrow-next:before {
    content: "\f123";
}

.ki-bold-arrow-back:before {
    content: "\f124";
}

.ki-bold-arrow-up:before {
    content: "\f125";
}

.ki-bold-check:before {
    content: "\f126";
}

.ki-bold-wide-arrow-down:before {
    content: "\f127";
}

.ki-bold-wide-arrow-up:before {
    content: "\f128";
}

.ki-bold-wide-arrow-next:before {
    content: "\f129";
}

.ki-bold-wide-arrow-back:before {
    content: "\f12a";
}

.ki-bold-long-arrow-up:before {
    content: "\f12b";
}

.ki-bold-long-arrow-down:before {
    content: "\f12c";
}

.ki-bold-long-arrow-back:before {
    content: "\f12d";
}

.ki-bold-long-arrow-next:before {
    content: "\f12e";
}

.ki-bold-check-1:before {
    content: "\f12f";
}

.ki-close:before {
    content: "\f130";
}

.ki-more-ver:before {
    content: "\f131";
}

.ki-bold-more-ver:before {
    content: "\f132";
}

.ki-more-hor:before {
    content: "\f133";
}

.ki-bold-more-hor:before {
    content: "\f134";
}

.ki-bold-menu:before {
    content: "\f135";
}

.ki-drag:before {
    content: "\f136";
}

.ki-bold-sort:before {
    content: "\f137";
}

.ki-eye:before {
    content: "\f138";
}

.ki-outline-info:before {
    content: "\f139";
}

.ki-menu:before {
    content: "\f13a";
}

.ki-menu-grid:before {
    content: "\f13b";
}

.ki-wrench:before {
    content: "\f13c";
}

.ki-gear:before {
    content: "\f13d";
}

.ki-info:before {
    content: "\f13e";
}

.ki-calendar-2:before {
    content: "\f13f";
}

.ki-calendar:before {
    content: "\f140";
}

.ki-calendar-today:before {
    content: "\f141";
}

.ki-clock:before {
    content: "\f142";
}

.ki-dots:before {
    content: "\f143";
}


/* custom for shipment table */

.searchBox#css_custom_input::placeholder {
    color: #b5b5c3 !important;
}

#css_custom .search-wrapper {
    max-height: 13vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1.75%;
    border-radius: 6px;
}

._loading_overlay_content {
    height: 67%;
}


/* .MuiTableRow-root:hover {
    cursor: pointer;
    background-color: #F0F1F5 !important;
} */

.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableSortLabel-root:hover,
.MuiTableSortLabel-icon {
    color: white !important;
}

.MuiButtonBase-root {
    width: max-content;
}

.form-control:disabled {
    background-color: #eef2f6 !important;
}


/* for stepper */

.steps {
    min-height: 90px;
    padding: 30px 0 0 0;
    font-family: ‘Open Sans’, sans-serif;
    position: relative
}

.steps .steps-container {
    background: #DDD;
    height: 10px;
    width: 100%;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -ms-border-radius: 10px;
    margin: 0;
    list-style: none
}

.steps .steps-container li {
    text-align: center;
    list-style: none;
    float: left
}

.steps .steps-container li .step {
    padding: 0 50px
}

.steps .steps-container li .step .step-image {
    margin: -14px 0 0 0
}

.steps .steps-container li .step .step-image span {
    background-color: #DDD;
    display: block;
    width: 37px;
    height: 37px;
    margin: 0 auto;
    border-radius: 37px;
    -moz-border-radius: 37px;
    -webkit-border-radius: 37px;
    -ms-border-radius: 37px
}

.steps .steps-container li .step .step-current {
    font-size: 15px;
    font-style: italic;
    color: #999;
    margin: 8px 0 0 0
}

.steps .steps-container li .step .step-description {
    font-size: 15px;
    font-style: italic;
    color: #538897
}

.steps .steps-container li.activated .step .step-image span {
    background-color: #5DC177
}

.steps .steps-container li.activated .step .step-image span:after {
    background-color: #FFF;
    display: block;
    content: ‘’;
    position: absolute;
    z-index: 1;
    width: 27px;
    height: 27px;
    margin: 5px;
    border-radius: 27px;
    -moz-border-radius: 27px;
    -webkit-border-radius: 27px;
    -ms-border-radius: 27px;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.15)
}

.steps .step-bar {
    background-color: #5DC177;
    height: 10px;
    position: absolute;
    top: 30px;
    border-radius: 10px 0 0 10px;
    -moz-border-radius: 10px 0 0 10px;
    -webkit-border-radius: 10px 0 0 10px;
    -ms-border-radius: 10px 0 0 10px
}

.steps .step-bar.last {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -ms-border-radius: 10px
}

.managed-width {
    max-width: fit-content !important;
}


/* for circle progress */

.circlex {
    background-color: #d83b01;
    border-radius: 50%;
    color: #fff;
    height: 2.5em;
    position: relative;
    width: 2.5em;
    border: 1px solid transparent;
}

.hoverx {
    cursor: pointer;
    background-color: #0078d4;
}

.circleTextx {
    text-align: center;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
}

#map-container {
    overflow-anchor: none;
}

/* For dashboard stepper */

/* for stepper */

.dash-steps {
    min-height: 0px;
    padding: 0px 0 0 0;
    font-family: ‘Open Sans’, sans-serif;
    position: relative
}

.dash-steps .dash-steps-container {
    background: #DDD;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -ms-border-radius: 10px;
    margin: 0;
    list-style: none
}

.dash-steps .dash-steps-container li {
    text-align: center;
    list-style: none;
    float: left
}

.dash-steps .dash-steps-container li .dash-step {
    /* padding: 0 50px */
}

.dash-steps .dash-steps-container li .dash-step .dash-step-image {
    margin: -7px 0 0 0
}

.dash-steps .dash-steps-container li .dash-step .dash-step-image span {
    background-color: #DDD;
    display: block;
    width: 18px;
    height: 18px;
    margin: 0 auto;
    border-radius: 37px;
    -moz-border-radius: 37px;
    -webkit-border-radius: 37px;
    -ms-border-radius: 37px
}

.dash-steps .dash-steps-container li .dash-step .dash-step-current {
    font-size: 15px;
    font-style: italic;
    color: #999;
    /* margin: 8px 0 0 0 */
}

.dash-steps .dash-steps-container li .dash-step .dash-step-description {
    font-size: 15px;
    font-style: italic;
    color: #538897
}

.dash-steps .dash-steps-container li.activated .dash-step .dash-step-image span {
    background-color: #5DC177
}

.dash-steps .dash-steps-container li.activated .dash-step .dash-step-image span:after {
    background-color: #FFF;
    display: block;
    content: ‘’;
    position: absolute;
    z-index: 1;
    width: 12px;
    height: 12px;
    margin: 5px;
    border-radius: 27px;
    -moz-border-radius: 27px;
    -webkit-border-radius: 27px;
    -ms-border-radius: 27px;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.15)
}

.dash-steps .dash-step-bar {
    background-color: #5DC177;
    height: 4px;
    position: absolute;
    top: 0px;
    border-radius: 10px 0 0 10px;
    -moz-border-radius: 10px 0 0 10px;
    -webkit-border-radius: 10px 0 0 10px;
    -ms-border-radius: 10px 0 0 10px
}

.dash-steps .dash-step-bar.last {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -ms-border-radius: 10px
}

.dash-managed-width {
    max-width: fit-content !important;
}

.font_medium {
    font-size: medium;
}